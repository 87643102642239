<!--
Copyright 2020 SADIK Central Developers
See the NOTICE file at the top-level directory of this distribution and at
https://github.com/getodk/central-frontend/blob/master/NOTICE.

This file is part of SADIK Central. It is subject to the license terms in
the LICENSE file found in the top-level directory of this distribution and at
https://www.apache.org/licenses/LICENSE-2.0. No part of SADIK Central,
including this file, may be copied, modified, propagated, or distributed
except according to the terms contained in the LICENSE file.
-->
<template>
  <li v-if="!visiblyLoggedIn" id="navbar-actions">
    <a href="#" @click.prevent>
      <span class="icon-user-circle-o"></span>{{ $t('notLoggedIn') }}
    </a>
  </li>
  <li v-else id="navbar-actions" class="dropdown">
    <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button"
      aria-haspopup="true" aria-expanded="false">
      <span class="icon-user-circle-o"></span>
      <span v-tooltip.text>{{ currentUser.displayName }}</span>
      <span class="caret"></span>
    </a>
    <ul class="dropdown-menu">
      <li>
        <router-link to="/account/edit">
          {{ $t('action.editProfile') }}
        </router-link>
      </li>
      <li>
        <a id="navbar-actions-log-out" href="#" @click.prevent="logOut">
          {{ $t('action.logOut') }}
        </a>
      </li>
    </ul>
  </li>
</template>

<script>
import { logOut } from '../../util/session';
import { noop } from '../../util/util';
import { useRequestData } from '../../request-data';

export default {
  name: 'NavbarActions',
  inject: ['container', 'alert', 'unsavedChanges', 'visiblyLoggedIn'],
  setup() {
    // The component does not assume that this data will exist when the
    // component is created.
    const { currentUser } = useRequestData();
    return { currentUser };
  },
  methods: {
    logOut() {
      if (this.unsavedChanges.confirm()) {
        logOut(this.container, false)
          .then(() => {
            this.alert.success(this.$t('alert.logOut'));
          })
          .catch(noop);
      }
    }
  }
};
</script>

<style lang="scss">
@import '../../assets/scss/mixins';

#navbar-actions {
  .dropdown-toggle .icon-user-circle-o + span {
    @include text-overflow-ellipsis;
    display: inline-block;
    max-width: 275px;
    vertical-align: top;
  }
}
</style>

<i18n lang="json5">
{
  "en": {
    // This text is shown if the user is not logged in. It is shown in the
    // navigation bar at the top of the page.
    "notLoggedIn": "Not logged in",
    "action": {
      "logOut": "Log out"
    },
    "alert": {
      "logOut": "You have logged out successfully."
    }
  }
}
</i18n>

<!-- Autogenerated by destructure.js -->
<i18n>
{
  "cs": {
    "notLoggedIn": "Nepřihlášen",
    "action": {
      "logOut": "Odhlásit se"
    },
    "alert": {
      "logOut": "Úspěšně jste se odhlásili."
    }
  },
  "de": {
    "notLoggedIn": "Nicht angemeldet",
    "action": {
      "logOut": "Ausloggen"
    },
    "alert": {
      "logOut": "Sie haben erfolgreich ausgeloggt."
    }
  },
  "es": {
    "notLoggedIn": "No ha iniciado sesión",
    "action": {
      "logOut": "Cerrar sesión"
    },
    "alert": {
      "logOut": "Ha cerrado la sesión correctamente."
    }
  },
  "fr": {
    "notLoggedIn": "Non connecté",
    "action": {
      "logOut": "Se déconnecter"
    },
    "alert": {
      "logOut": "Vous avez été déconnecté avec succès."
    }
  },
  "id": {
    "notLoggedIn": "Belum masuk",
    "action": {
      "logOut": "Keluar"
    },
    "alert": {
      "logOut": "Anda sudah berhasil keluar."
    }
  },
  "it": {
    "notLoggedIn": "Accesso non effettuato",
    "action": {
      "logOut": "Disconnettersi"
    },
    "alert": {
      "logOut": "Ti sei disconnesso con successo."
    }
  },
  "ja": {
    "notLoggedIn": "ログイン",
    "action": {
      "logOut": "ログアウト"
    },
    "alert": {
      "logOut": "ログアウトに成功しました。"
    }
  },
  "sw": {
    "notLoggedIn": "Hujaingia",
    "action": {
      "logOut": "toka kwenye ukurasa"
    },
    "alert": {
      "logOut": "Umetoka kwenye ukurasa kwa mafanikio."
    }
  }
}
</i18n>
